.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    background-color: #fff;
}

.header-row {
    justify-content: space-between;
    align-items: center;
    padding-block: 10px;
}

.header-contact {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.header-contact-col {
   text-align: right;
}

.header-contact a {
    color: var(--clr-blue-shade);
    font-size: var(--dial-white-font);
}

.dial-white {
    width: var(--dial-white-font);
    height: var(--dial-white-font);
}
.dial-white-wrapper {
    position: relative;
    display: flex;
    right: 10px;
}

.dial-white-wrapper::before {
    content: '';
    width: 45px;
    height: 45px;
    background-color: #B22134;
    position: absolute;
    top: -8px;
    left: -8px;
    border: 10px solid #B22134;
    z-index: -1;
    border-radius: 30px;
}

.header-tel {
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 575px) and (max-width: 1025px) {

    .broker-logo {
        width: 400px;
    }

    .header-contact a {
        font-size: 3vw;
    }

    .dial-white-wrapper::before {
        width: 5vw;
        height: 5vw;
        left: -6px;
        top: -6px;
    }
}

@media (min-width: 769px) and (max-width: 1025px) {
    .dial-white-wrapper::before {
        left: -11px;
        top: -11px;
    }
}

@media (min-width: 575px) {
    .header {
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    }
}

@media (max-width: 575px) {
    .USlogo{
        margin-left: -1rem!important;
        padding-right: 1rem!important;
    }
    .header {
        box-shadow: 0 0 34px 2px #fff;
    }
    .header-contact a {
        font-size: 5vw;
    }
    .header-row {
        padding-top: 15px;
        padding-bottom: 0;
        gap: 10px;
    }
    .header-contact {
        justify-content: center;
        gap: 10px;
    }
    .dial-white ,
    .dial-white-wrapper::before{
        display: none;
    }
    .header-tel {
        position: relative;
    }
    .header-tel::before {
        content: '';
        position: absolute;
        background-image: url('../images/Phone-red.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 5vw;
        height: 5vw;
        left: -25px;
        top: 1vw;
    }
    
}

@media only screen and (min-width: 992px) {
    .mobile-AccessibilityMenu-show {
      display: none;
    }
  }
  @media only screen and (max-width: 991px) {
    .desktop-AccessibilityMenu-show {
      display: none;
    }
    .mobile-AccessibilityMenu-show {
      position: absolute;
      right: 0rem;
      top: 0rem;
      z-index: 2;
    }
  }

  @media only screen and (min-width: 768px) {
    .mobile-AccessibilityMenu-show {
      display: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .desktop-AccessibilityMenu-show {
      display: none;
    }
    .mobile-AccessibilityMenu-show {
      position: absolute;
      right: 0rem;
      top: 0rem;
      z-index: 2;
    }
  }