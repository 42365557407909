.footer-row {
    justify-content: center;
    align-items: center;
}

.footer > .container {
    padding-bottom: 4vw;
}

.footer-bottom {
    border-bottom: 20px solid var(--clr-blue-shade);
}

.footer-content {
    font-size: 1.1vw;
}

.footer-content a:hover {
    opacity: 0.5;
}

.eho-col {
    text-align: right;
}

@media (min-width: 575px) and (max-width: 1025px) {
    
    .footer-content {
        font-size: 1.5vw;
    }
}

/* @media (min-width: 769px) and (max-width: 1025px) {
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
} */

@media (max-width: 575px) {
    .eho-col,
    .footer-content {
        text-align: center;
    }

    .eho-logo {
        width: 78px;
        height: 78px;
    }

    .footer-row {
        gap: 20px;
    }

    .footer-content {
        font-size: 3.5vw;
    }

    .footer > .container {
        padding-bottom: 1rem;
    }

    .footer-para {
        padding-inline: 0 !important;
    }
}