* {
    --font-dot5: 0.5rem;
    --font-dot8: 0.8rem;
    --font-1: 1rem;
    --font-1dot1: 1.1rem;
    --font-1dot3: 1.3rem;
    --font-1dot4: 1.4rem;
    --font-1dot5: 1.5rem;
    --font-1dot7: 1.7rem;
    --font-2: 2rem;
    --font-2dot3: 2.3rem;
    --font-2dot5: 2.5rem;
    --font-2dot7: 2.7rem;
    --font-3: 3rem;
    --font-3dot5: 3.5rem;
    --font-4: 4rem;
    --font-4dot5: 4.5rem;
    --font-5: 5rem;
    --font-6: 6rem;
    --font-25: 25rem;
    --clr-red-shade: #B22134;
    --clr-blue-shade: #010069;
    --clr-consent-grey: #605852;
    --dial-white-font: 30px;
}
