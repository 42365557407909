.banner {
    margin-top: 4rem;
    position: relative;
}

.left-content-title {
    padding-top: 5vw;
    padding-bottom: 3vw;
}

.banner-image {
    background-image: url('../images/BannerLeftSide.svg'),
        url('../images/BannerRightSide.png');
    background-repeat: no-repeat, no-repeat;
    background-position: -150px 40px, 120% -100px;
    background-size: 15%, 50%;
    width: 100%;
    height: 900px;
    z-index: -2;
    position: absolute;
}

.left-content-title div:nth-child(1) {
    font-size: 3.3vw;
} 
.left-content-title div:nth-child(2) {
    font-size: 10.5vw;
    line-height: 1;
    text-shadow: -1.67136px 2.22849px 1.11424px rgba(0, 0, 0, 0.3);
} 

.left-content-details {
    justify-content: center;
    align-items: center;
}

.details-desc .name {
    font-size: 2.8vw;
}
.details-desc .work {
    font-size: 1.7vw;
    line-height: 1;
    color: var(--clr-blue-shade);
}
.work div {
    line-height: 1.4;
}

.tel-div,
.mail-div{
    line-height: 1.4;
}

.tel-div .text-col,
.mail-div .text-col{
    padding-left: 0 !important;
}

.broker-image {
    width: 20vw;
    height: 20vw;
}

.address-wrapper {
    width: fit-content;
}

.meeting-btn.btn-primary {
    background-color: var(--clr-blue-shade);
    border-radius: 10px;
    font-size: 23px;
    line-height: 30px;
    border: none;
}

.meeting-btn.btn-primary:hover {
    background-color: #3d3d92;
}

.meeting-btn-row {
    align-items: center;
    padding-inline: 5px;
    padding-block: 0;
}

.meeting-btn-row > .column:nth-child(1) {
    padding-right: 0 !important;
    text-align: right;
}
.meeting-btn-row > .column:nth-child(2) {
    padding-left: 0 !important;
}

.meeting-btn {
    min-width: 28ch !important;
}

@media screen and (max-width: 575px) {
    .meeting-btn.btn-primary {
        font-size: 15px;
        line-height: 20px;
    }
}

@media (min-width: 575px) and (max-width: 1025px) {
    .banner {
        margin-top:6rem;
    }

    .left-content-details {
        justify-content: flex-start;
    }

    .left-content-title div:nth-child(1) {
        font-size: 4vw;
    }
    .left-content-title div:nth-child(2) {
        font-size: 11vw;
    }
    .details-desc .work {
        font-size: 2.4vw;
    }
    .details-desc .name {
        font-size: 4vw;
    }

    .details-img {
        background-color: transparent;
    }

    .map-image {
        transform: scale(2) translateX(2px);
        width: 3vw;
    }

    .broker-image {
        width: 18vw;
    }
}

@media (min-width: 768px) and (max-width: 1025px) {
    .banner {
        margin-top: 7vw;
    }

    .details-desc .work {
        font-size: 2vw;
    }
}

@media (max-width: 575px) {
    .banner {
        margin-top: 0;
    }

    .banner-image {
        background-image: url('../images/BannerMobile.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 100%;
        height: 100vw;
        z-index: -2;
        position: relative;
    }

    .left-content-title div:nth-child(1) {
        font-size: 20px;
        text-align: center;
    }
    .left-content-title div:nth-child(2) {
        font-size: 20vw;
    }
    .left-content-details {
        text-align: center;
    }
    .details-image img {
        width: 40vw;
        height: 40vw;
    }
    .tel-div .img-col,
    .mail-div .img-col,
    .address-wrapper .img-col {
        display: none;
    }
    .tel-div .row,
    .mail-div .row,
    .address-wrapper .row {
        justify-content: center;
    }
    .work {
        font-weight: 400;
    }
    .details-desc .work {
        font-size: 5vw;
    }
    .work>div:nth-child(1) {
        font-weight: 600;
    }
    .details-desc .name {
        font-size: 8vw;
    }

    .left-content-details {
        position: relative;
    }

    .left-content-details::before {
        content: '';
        width: 100%;
        height: 83%;
        position: absolute;
        bottom: -3%;
        left: 0;
        border: 2px solid var(--clr-blue-shade);
        border-radius: 15px;
        border-top: none;
        z-index: -1;
    }

    .details-image {
        padding-inline: 10px;
        /* background-color: #fff; */
        z-index: -2;
    }

    .broker-image-wrapper {
        position: relative;
    }

    .broker-image-wrapper::before,
    .broker-image-wrapper::after {
        content: '';
        width: 20vw;
        height: 0;
        position: absolute;
        color: green;
        top: 51%;
        transform: translateY(-50%);
        border-top: 2px solid var(--clr-blue-shade);
        border-radius: 15px;
    }

    .broker-image-wrapper::before {
        left: 0;
    }
    .broker-image-wrapper::after {
        right: 0;
    }

    .address-wrapper {
        width: inherit;
    }
}

