.consent-text ,
.consent-div p {
    font-size: 0.65rem;
    line-height: 1.3;
    text-align: justify;
    color: var(--clr-consent-grey);
    font-family: "Poppins-Regular";
    margin-bottom: 5px;
}
  
.consent-div p.consent-title {
    font-size: 12px;
    color: var(--clr-consent-grey);
    font-family: "Poppins-Regular";
    font-weight: bold;
    margin-top: 5px;
}
.consent-text.checkboxConsent {
    font-family: "Roboto-Bold";
}

.consent-div p a {
    color: #2ba8e0;
}

.consent-chkbox-row {
    display: flex;
    justify-content: left;
    text-align: left;
    align-items: center;
    font-family: 'Poppins-SemiBold';
    font-size: 0.75rem;
    line-height: 1.2;
    margin-block: 0.5rem;
    margin-inline: 0;
}

#consentChkBox,
#consentFalseChkBox {
    margin-top: 0;
    border-color: transparent;
    background-color: transparent;
}

.ideal-input {
    color: var(--clr-blue-shade);
}

.ideal-input input:checked:before ,
input#consentFalseChkBox:checked:before {
    content: '✓';
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: var(--clr-blue-shade);
    font-weight: bold;
}
.ideal-input input::before,
input#consentFalseChkBox::before {
    content:  '';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: transparent;
    border: 1px solid #083F88;
    border-radius: 2px;
    cursor: pointer;
}

.consent-chkbox-row .form-check-input,
.consent-chkbox-row .form-check-label {
    cursor: pointer;
}


.broker-form-container .btnSubmit.btn.btn-primary{
    background: linear-gradient(0deg, #083F88 35%, #00AAE4 169.64%);
    border-radius: 11px;
    padding: 3px 17px;
    padding-bottom: 5px;
    color: white;
    /* font-weight: bold; */
}

.broker-form-container .btnSubmit.btn.btn-primary:hover{
    background-color: #0475aa;
}

.validationMsges{
    text-align: center;
    font-family: "Roboto-Regular";
}

@media screen and (max-width: 575px) { /** max-width: 575px : corresponds to selector in BannerSection.css */
    
    .consent-div p{
        text-align: left;
    }

    .consent-chkbox-row {
        margin-block: 0.3rem;
    }
}

@media screen and (max-width: 699px) {

    .consent-div p {
        line-height: 1.3;
    }
}
