.quote-main-parent {
    margin-block: 4vw;
}
.quote-row {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.quote-title {
    font-size: 2.4vw;
    line-height: 1.2;
    transform: translateY(23px);
    background-color: #fff;
    width: fit-content;
    margin-inline: auto;
    padding-inline: 10px;
}
.quote-desc {
    font-size: 1.4vw;
    line-height: 1.5;
    position: relative;
    transform: translateY(0px);
    padding-block: 2rem;
}

.padding-wrapper {
    padding-inline: 8rem;
    padding-block: 3rem;
    border: 3px solid var(--clr-blue-shade);
    border-radius: 28px;
}

.quote-symbol-wrapper {
    position: relative;
}

.quote-symbol-wrapper::before,
.quote-symbol-wrapper::after {
    content: '';
    background-image: url('../images/RR-Quotes.svg');
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    position: absolute;
}

.quote-symbol-wrapper::before {
    top: 0;
    left: -60px;
}
.quote-symbol-wrapper::after {
    bottom: 0;
    right: -60px;
    transform: rotate(180deg);
}

@media (min-width: 575px) and (max-width: 1025px) {
    .quote-desc {
        font-size: 2vw;
    }
    .quote-title {
        font-size: 4vw;
    }
    .padding-wrapper {
        padding-inline: 10vw;
        padding-block: 1.5rem;
    }
}

@media (min-width: 769px) and (max-width: 1025px) {
    .quote-title {
        font-size: 3vw;
    }
}
@media (max-width: 575px) {
    .quote-main-parent {
        margin-block: 9vw;
    }
    .quote-title {
        font-size: 5.3vw;
        background-color: transparent;
    }
    .quote-desc {
        font-size: 4.5vw;
        padding-block: 0;
    }
    .padding-wrapper {
        border: none;
        padding-inline: 8.2vw;
        padding-top: 12vw;
        padding-bottom: 5vw;
    }
    .quote-symbol-wrapper::before,
    .quote-symbol-wrapper::after {
        transform: scale(0.6);
    }
    .quote-symbol-wrapper::before {
        top: -20px;
    }
    .quote-symbol-wrapper::after {
        bottom: -20px;
        transform: scale(0.6) rotate(180deg);
    }
}
@media (max-width: 399px) {
    
    .quote-title {
        font-size: 5vw;
    }

    .quote-desc {
        font-size: 4vw;
    }
}