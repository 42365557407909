@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins-Bold'), url(../fonts/Poppins-Bold.ttf) format('truetype');
    font-display: swap;
}
  
@font-face {
    font-family: 'Poppins-SemiBold';
    src: local('Poppins-SemiBold'), url(../fonts/Poppins-SemiBold.ttf) format('truetype');
    font-display: swap;
}
  
@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins-Medium.ttf'),
      url(../fonts/Poppins-Medium.ttf) format('truetype');
    font-display: swap;
}
  
@font-face {
    font-family: 'Poppins-ExtraBold';
    src: local('Poppins-ExtraBold.ttf'),
      url(../fonts/Poppins-ExtraBold.ttf) format('truetype');
    font-display: swap;
}
  
@font-face {
    font-family: 'Poppins-Regular';
    src: local('Poppins-Regular.ttf'),
      url(../fonts/Poppins-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Lucky_Fellas';
    src: local('Lucky_Fellas_W00_Regular[1].ttf'),
      url(../fonts/Lucky_Fellas_W00_Regular[1].ttf) format('truetype');
    font-display: swap;
}

.poppins-regular{
  font-family: 'Poppins-Regular';
}
.poppins-bold{
  font-family: 'Poppins-Bold';
}
.poppins-semibold{
  font-family: 'Poppins-SemiBold';
  /* font-weight: 500; */
}
.lucky-fellas{
  font-family: 'Lucky_Fellas';
}

.color-inherit {
  color: inherit;
}

.text-align-left {
    text-align: left;
}
.text-justify {
    text-align: justify;
}

.width-fit-content {
  width: fit-content !important;
}

.no-underline {
  text-decoration: none;
}

.relative-parent {
  position: relative;
}

.one-liner {
  display: inline-block;
}

.telephone, .mail , .address{
  text-decoration: none;
}
.telephone:hover,
.mail:hover,
.address:hover{
  text-decoration: initial;
}

.uppercase {
  text-transform: uppercase;
}

.clr-red-shade {
  color: var(--clr-red-shade);
}
.clr-blue-shade {
  color: var(--clr-blue-shade);
}
.clr-black-shade {
  color: #000;
}

.decor-image {
  z-index: -2;
  pointer-events: none;
}

@media (min-width: 1200px) {
  /* .header-container.container , */
  .banner-container.container {
      max-width: 1180px;
  }
}

@media (min-width: 1200px) {
  .container.section-container {
    max-width: 80vw;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .container.section-container {
    max-width: 90vw;
  }
}
@media (max-width: 575px) {
  .container
   {
    max-width: 90vw;
  }
}

  