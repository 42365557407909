.desktop-banner-dot-image,
.left-ellipse-image,
.right-ellipse-image,
.mobile-ellipse,
.bottom-circles-image,
.two-dots-wrapper {
    display: none;
    position: absolute;
    z-index: -2;
}

@media (min-width: 575px) {

    .desktop-banner-dot-image,
    .left-ellipse-image,
    .right-ellipse-image,
    .bottom-circles-image,
    .two-dots-wrapper {
        display: block;
        z-index: -2;
    }

    .mobile-ellipse-1,
    .mobile-ellipse-2,
    .mobile-ellipse {
        display: none;
    }
    
    .desktop-banner-dot-image {
        position: absolute;
        left: -20px;
        top: 23vw;
        width: 3.5vw;
        height: 3.5vw;
    }
    .left-ellipse-image {
        position: absolute;
        left: 0;
        top: 28vw;
    }
    .right-ellipse-image {
        position: absolute;
        right: 0;
        top: 33vw;
    }
    .bottom-circles-image {
        position: absolute;
        right: 0;
        top: 82vw;
    }
    .two-dots-wrapper {
        position: absolute;
        left: 0;
        top: 84vw;
    }
    .two-dots-wrapper img:nth-child(1) {
        position: relative;
        left: 20px;
    }
    .two-dots-wrapper img:nth-child(2) {
        position: relative;
        right: -20px;
        bottom: -10px;
    }
}

@media (min-width: 769px) and (max-width:1025px) {
    .bottom-circles-image {
        right: 0;
        top: 106vw;
    }
    .two-dots-wrapper {
        position: absolute;
        left: 0;
        top: 100vw;
    }
}

@media (min-width: 575px) and (max-width:768px) {
    .left-ellipse-image {
        left: 0;
        top: 18vw;
    }
    .right-ellipse-image {
        right: 0;
        top: 27vw;
    }
    .bottom-circles-image {
        right: 0;
        top: 106vw;
    }
    .two-dots-wrapper {
        position: absolute;
        top: 118vw;
    }
}

@media (max-width: 575px) {

    .App {
        position: relative;
    }
    .mobile-ellipse {
        display: block;
        position: absolute;
    }
    .left-ellipse-image {
        position: absolute;
        display: block;
        left: -10px;
        top: 104vw;
        width: 53vw;
        height: 30vh;
    }
    .right-ellipse-image {
        position: absolute;
        display: block;
        right: 0px;
        top: 167vw;
        width: 40vw;
    }
    .mobile-ellipse-1 {
        left: -10px;
        top: 128vh;
    }
    .mobile-ellipse-2 {
        right: 0px;
        bottom: 0%;
    }
}