.form-container {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 10px #00000026;
    padding: 3rem !important;
    margin-top: -5%;
}

.form-container input.form-control,
.datepicker-input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 1.5px solid var(--clr-blue-shade);
    margin-bottom: 18px;
    padding-inline: 0;
    font-family: 'Poppins-Regular';
    font-size: 21.6632px;
    line-height: 32px;
}

.form-container .form-label  {
    font-family: 'Poppins-Regular';
    font-size: 21.6632px;
    line-height: 32px;
}

.form-container .form-select  {
    border-radius: 10px;
    border: 1.5px solid var(--clr-blue-shade);
    margin-bottom: 18px;
    padding-inline: 10px;
    font-family: 'Poppins-Regular';
    font-size: 21.6632px;
    line-height: 32px;
}

.form-container textarea.form-control {
    border-radius: 5px;
    border: 1.5px solid var(--clr-blue-shade);
    margin-bottom: 18px;
    padding-inline: 0;
    font-family: 'Poppins-Regular';
    font-size: 21.6632px;
    line-height: 32px;
}

.datepicker-input {
    width: 100%;
    position: relative;
}

/* .datepicker-input::before { */
.react-datepicker__input-container::before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    right: -2%;
    top: 5%;
    z-index: 1;
    /* background-color: red; */
    background-image: url('../images/calendar-icon.svg');
    background-repeat: no-repeat;
    pointer-events: none;
    /* cursor: pointer; */
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
    left: -20px !important;
}

.form-container .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    border-top-color: var(--clr-blue-shade);
}
.form-container .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.form-container .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after  {
    border-bottom-color: var(--clr-blue-shade);
}

.form-container .react-datepicker__header {
    background-color: var(--clr-blue-shade);
}

.form-container .react-datepicker__current-month,
.form-container .react-datepicker .react-datepicker__day-name {
    color: #fff !important;

}

.form-container .react-datepicker__month {
    border: 1px solid var(--clr-blue-shade);
    border-top: 0;
    margin: 0;
}
.form-container .react-datepicker {
    border: none;
}
.form-container .react-datepicker__current-month {
    border-bottom: 1px solid #fff;
}
.form-container .react-datepicker__navigation {
    top: 6px;
}

.form-container .react-datepicker-popper {
    transform: translate3d(333%, 1392px, 0px);
}

.form-container .react-datepicker-popper * {
    font-family: 'Poppins-Regular';
}

.btn-row {
    justify-content: center;
}

.btn-row button {
    width: 100%;
    border-radius: 6px;
    font-family: 'Poppins-Regular';
}

.btn-row .btn {
    padding: .375rem 5px !important;
}

.btn-row-parent-col {
    margin-inline: auto;
}

.meeting-btn-col button {
    background-color: var(--clr-blue-shade);
    border-color: var(--clr-blue-shade);
    transition: all 0.1s linear;
}
.callback-btn-col button {
    background-color: var(--clr-red-shade);
    border-color: var(--clr-red-shade);
    transition: all 0.1s linear;
}

.meeting-btn-col button:hover {
    background-color: #0502a7;
    border-color: #0502a7;
}
.callback-btn-col button:hover {
    background-color: #db2a42;
    border-color: #db2a42;
}

.modal-title-header {
    font-family: 'Poppins-Regular';
    font-size: 21.6632px;
}

.modal-text-content {
    font-family: 'Poppins-Regular';
    font-size: 21.6632px;
}

.close-btn {
    font-family: 'Poppins-Regular';
    font-size: 21.6632px;
}

@media screen and (max-width: 425px) {
    .form-container input.form-control,
    .form-container textarea.form-control,
    .datepicker-input {
        font-size: 16px;
        line-height: 25px;
    }

    .form-container .form-label  {
        font-size: 16px;
        line-height: 25px;
    }
    
    .form-container .form-select  {
        font-size: 16px;
        line-height: 25px;
    }

    .modal-title-header {
        font-size: 16px;
    }
    
    .modal-text-content {
        font-size: 16px;
    }
    
    .close-btn {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .form-container {
        padding: 2rem !important;
    }
}
